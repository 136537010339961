import { useState } from 'react';
import ViewState from 'components/data/ViewState';
import cloneDeep from 'helpers/cloneDeep';

/**
 * Use storage state.
 * A state hook that also saved the state to either local or session storage.
 * @param key - The key to store the state in the storage.
 * @param defaultValue - The default value to use when the state is not in the storage.
 * @param storageType - The type of storage to use.
 * @returns - The state and a function to set the state.
 */
const useStorageState = <Data>(
    key: string,
    subKey = '',
    defaultValue: Data | undefined,
    typeOfStorage: 'local' | 'session' = 'local'
): [Data, (newState: Data) => void] => {
    /**
     * Get the state from the storage or use the default value.
     */
    const [state, _setState] = useState<Data>(() => {
        const storedState = ViewState.get(key, subKey, typeOfStorage);
        return storedState ?? cloneDeep(defaultValue);
    });

    /**
     * Set the state and store it in the storage.
     * @param newState - The new state to set.
     */
    const setState = (newState: Data): void => {
        _setState(newState);
        ViewState.set(key, subKey, newState, typeOfStorage);
    };

    return [state, setState];
};

export { useStorageState };
